import { useUser as useUserNextAuth } from '@auth0/nextjs-auth0/client'
// import { ADMIN } from '@consts/roles'
// import PermissionsContext from 'context/PermissionsCtx'
// import { useContext, useMemo } from 'react'

export default function useUser() {
  const { user } = useUserNextAuth()
  // const { handlePermissions, permissions } = useContext(PermissionsContext)
  // const isAdmin = useMemo(() => permissions && permissions?.includes(ADMIN), [permissions])

  return {
    email: user?.email,
    isAdmin: false,
    loading: !user,
    name: user?.name,
    nickname: user?.nickname
  }
}
