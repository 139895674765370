import { FIELDS_NAMES } from '@consts/risk-credit/personal_data/field_names'

export const ONBOARDING = 'onboarding'
export const DECLARATIVE = 'declarative'
export const RISK_EVALUATION = 'risk_evaluation'
export const DOCUMENT_PROCESSOR = 'document_processor'
export const DOCUMENT_PROCESSOR_CREATE = 'document_processor_create'
export const READINGS = 'readings'
export const REPORTING = 'reporting'
export const SIMULATOR = 'simulador'
export const SIMULATION = 'simulation'
export const RISK_CREDIT = 'risk-credit'

export const SUBMISSIONS = 'solicitudes'

export const APPLICATION_TYPES = {
  ONBOARDING,
  RISK_EVALUATION,
  READINGS
} as const

export const APPLICATION_TYPES_LABEL = {
  [ONBOARDING]: 'onboarding.label',
  [RISK_EVALUATION]: 'risk_evaluation.label'
}

const STATUS_PENDING = 'pending'
const STATUS_OK = 'ok'
const STATUS_ERROR = 'error'

export const STATUSES = {
  STATUS_PENDING,
  STATUS_OK,
  STATUS_ERROR
}

const CREATED = 'created'
const MAIL_SENT = 'mail_sent'
const IN_PROCESS = 'in_process'
const APPROVED = 'approved'
const REJECTED = 'rejected'
const FIXING = 'fixing'
const NOT_INTERESTED = 'not_interested'
const USER_ACCOUNT_CREATED = 'user_account_created'
const CREDENTIALS_COMPLETED = 'credentials_completed'
const AD_COMPLETED = 'ad_completed'
const AD_START = 'ad_start'
const CANCELED = 'canceled'
const CREDENTIALS_APPROVED = 'credentials_approved'
const CREDENTIALS_REJECTED = 'credentials_rejected'
const FINAL_REVIEW = 'final_review'
const IN_REPAIR = 'in_repair'
const INFORMATION_RECEPTION = 'information_reception'
const INFORMATION_REVISION = 'information_revision'
const PRE_APPROVED = 'pre_approved'
const PRE_EVALUATION = 'pre_evaluation'
const REJECTED_COMMERCIAL = 'rejected_commercial'
const REJECTED_RISK = 'rejected_risk'
const AD_STARTED = 'ad_started'
const CREDENTIALS_STEP_COMPLETED = 'credentials_step_completed'
const CO_APPLICANT_INFORMATION_RECEPTION = 'co_applicant_information_reception'
const MAIN_INFORMATION_RECEPTION = 'main_information_reception'
const MAIN_INFORMATION_COMPLETED = 'main_information_completed'
const PROPERTY_APPRAISAL = 'property_appraisal'
const LIFE_INSURANCE = 'life_insurance'
const NOTARY = 'notary'
const TRANSACTION_SETTLED = 'transaction_settled'
const CLOSED_CREDIT_SCORING = 'closed_credit_scoring'
const CLOSED_INACTIVE = 'closed_inactive'
const CLOSED_OTHER = 'closed_other'
const CLOSED_DUPLICATE = 'closed_duplicate'
const CLOSED_OTHER_WITH_NOTIFICATION = 'closed_other_with_notification'
const PROPERTY_SELECTION = 'property_selection'
const COMPLIMENTARY_INFORMATION_RECEPTION = 'complimentary_information_reception'
const INFORMATION_COMPLETED = 'information_completed'
const COMPLEMENTARY_INFORMATION_RECEPTION = 'complementary_information_reception'
const TRANSACTION_SIGNED = 'transaction_signed'
const SUBMITTED_FOR_REVIEW = 'submitted_for_review'
const APPROVED_BY_COMMITTEE = 'approved_by_committee'
const REOPENED = 'reopened'
const PROPERTY_DILLIGENCE = 'property_dilligence'
const TRANSACTION_DILLIGENCE_OK = 'property_dilligence'
const DEED_OF_TRANSFER = 'property_dilligence'

export const RISK_EVALUATION_STATUSES = {
  CREATED,
  MAIL_SENT,
  IN_PROCESS,
  APPROVED,
  REJECTED,
  FIXING,
  NOT_INTERESTED,
  USER_ACCOUNT_CREATED,
  CREDENTIALS_COMPLETED,
  AD_COMPLETED,
  AD_START,
  CANCELED,
  CREDENTIALS_APPROVED,
  CREDENTIALS_REJECTED,
  FINAL_REVIEW,
  IN_REPAIR,
  INFORMATION_RECEPTION,
  INFORMATION_REVISION,
  PRE_APPROVED,
  PRE_EVALUATION,
  REJECTED_COMMERCIAL,
  REJECTED_RISK,
  AD_STARTED,
  CREDENTIALS_STEP_COMPLETED,
  CO_APPLICANT_INFORMATION_RECEPTION,
  MAIN_INFORMATION_RECEPTION,
  MAIN_INFORMATION_COMPLETED,
  PROPERTY_APPRAISAL,
  LIFE_INSURANCE,
  NOTARY,
  TRANSACTION_SETTLED,
  CLOSED_CREDIT_SCORING,
  CLOSED_INACTIVE,
  CLOSED_OTHER,
  CLOSED_DUPLICATE,
  CLOSED_OTHER_WITH_NOTIFICATION,
  PROPERTY_SELECTION,
  COMPLIMENTARY_INFORMATION_RECEPTION,
  INFORMATION_COMPLETED,
  COMPLEMENTARY_INFORMATION_RECEPTION,
  TRANSACTION_SIGNED,
  SUBMITTED_FOR_REVIEW,
  APPROVED_BY_COMMITTEE,
  REOPENED,
  PROPERTY_DILLIGENCE,
  TRANSACTION_DILLIGENCE_OK,
  DEED_OF_TRANSFER
} as const

export const ONBOARDING_PENDING = 'onboarding.pending'
export const ONBOARDING_APPROVED = 'onboarding.approved'
export const ONBOARDING_REJECTED = 'onboarding.rejected'

export const ONBOARDING_STATUSES = {
  ONBOARDING_PENDING,
  ONBOARDING_APPROVED,
  ONBOARDING_REJECTED
} as const

const STATS_TAB = 'stats'
const DASHBOARD_TAB = 'dashboard'

export const APPLICATION_TABS = {
  STATS_TAB,
  DASHBOARD_TAB
} as const

export const APPLICATION_STATUSES = {
  ...RISK_EVALUATION_STATUSES,
  ...ONBOARDING_STATUSES
} as const

export const NEW_APP_FIELDS = {
  FIRST_NAMES: FIELDS_NAMES.FIRST_NAMES,
  MOTHER_SURNAME: FIELDS_NAMES.MOTHER_SURNAME,
  FATHER_SURNAME: FIELDS_NAMES.FATHER_SURNAME,
  PHONE: FIELDS_NAMES.PHONE,
  EMAIL: FIELDS_NAMES.EMAIL,
  RUT: FIELDS_NAMES.PERSON_ID,
  FINANCIAL_PRODUCT_TYPE: FIELDS_NAMES.PRODUCT_ID
}

export const LABEL_TRANSLATION = {
  [NEW_APP_FIELDS.FIRST_NAMES]: 'firstName',
  [NEW_APP_FIELDS.FATHER_SURNAME]: 'fatherSurname',
  [NEW_APP_FIELDS.MOTHER_SURNAME]: 'motherSurname',
  [NEW_APP_FIELDS.PHONE]: 'phone',
  [NEW_APP_FIELDS.EMAIL]: 'email',
  [NEW_APP_FIELDS.FINANCIAL_PRODUCT_TYPE]: 'productType'
}

export const APPLICATION_KINDS = {
  MAIN: 'main',
  SPOUSE: 'spouse',
  CO_BORROWER: 'co_borrower',
  GROUP: 'group'
} as const
export const UNDEFINED_APPLICATION_KIND = 'undefined'

export type ApplicationKind = (typeof APPLICATION_KINDS)[keyof typeof APPLICATION_KINDS]
export type ApplicationKindExcludeGroup = Exclude<ApplicationKind, 'group'>

export type NewAppFieldNames = (typeof NEW_APP_FIELDS)[keyof typeof NEW_APP_FIELDS]

export const AREA_COLLABORATORS = {
  COMMERCIAL: 'commercial',
  RISK: 'risk',
  MANAGEMENT: 'management'
}
