import { DELETE, GET } from '@consts/methods'
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DEFAULT_ERROR, ERROR_INVALID_DATA, ERROR_NOTIFICATION_ERROR } from '@consts/Errors/type'
import { ERROR_MESSAGES } from '@consts/messages'
import { ERROR_NOT_FOUND_RESOURCE, ERROR_OPERATION_NOT_ALLOWED } from '@consts/Errors/type'
import {
  ForbiddenError,
  NotFoundError,
  NotFoundResourceError,
  ValidationError,
  OperationNotAllowedError,
  NotificationError
} from 'entities/errors'
import { APPLICATION_GROUP_NOT_FOUND, CONTENT_FILE_NOT_ALLOWED } from '@consts/Errors/code'

const includeBody = ['POST', 'PUT', 'DELETE']

type SideEffect = {
  redirect_404: boolean
}

interface Props {
  url: string
  body?: unknown
  method?: string
  sideEffect?: SideEffect
  callbackError?: (error: string) => void
}

export async function fetchData({
  url,
  body,
  method = GET,
  callbackError,
  sideEffect = {
    redirect_404: true
  }
}: Props): Promise<any> {
  const response = await fetch(url, {
    method,
    body: includeBody?.includes(method) ? JSON.stringify(body) : undefined
  })

  if (response.status === 401) {
    callbackError && callbackError(ERROR_MESSAGES.UNAUTHORIZED)
    window.location.replace('/api/auth/logout')
    throw new Error(ERROR_MESSAGES.UNAUTHORIZED)
  }

  if (response.status === 403) {
    callbackError && callbackError(ERROR_MESSAGES.FORBBIDEN_ERROR)
    throw new ForbiddenError(ERROR_MESSAGES.FORBBIDEN_ERROR, response.status)
  }

  if (method === DELETE && response.status === 204) {
    return null
  }
  if (response.status === 204) return null

  const data = await response?.json()

  if (data.code === APPLICATION_GROUP_NOT_FOUND) {
    throw new NotFoundError(APPLICATION_GROUP_NOT_FOUND, data.message)
  }

  if (response.status === 404) {
    callbackError && callbackError(ERROR_MESSAGES.NOT_FOUND_RESOURCE)
    if (sideEffect.redirect_404) window.location.replace('/404')
    throw new NotFoundError(ERROR_NOT_FOUND_RESOURCE, response.status)
  }

  if (data.type === ERROR_INVALID_DATA) {
    throw new ValidationError(`${data.type}: ${data.code}`, data.errors, response.status)
  }

  if (data.type === ERROR_NOT_FOUND_RESOURCE) {
    callbackError && callbackError(ERROR_MESSAGES.NOT_FOUND_RESOURCE)
    throw new NotFoundResourceError(
      ERROR_NOT_FOUND_RESOURCE,
      data.messageresponse.status,
      response.status
    )
  }
  if (data.type === ERROR_NOTIFICATION_ERROR) {
    callbackError && callbackError(ERROR_MESSAGES.NOT_FOUND_RESOURCE)
    throw new NotificationError(data.code, response.status)
  }

  if (data.type === ERROR_OPERATION_NOT_ALLOWED) {
    const message = data.message
      ? data.message
      : data.code === CONTENT_FILE_NOT_ALLOWED
      ? ERROR_MESSAGES.FILE_NOT_ALLOWED
      : ERROR_MESSAGES.OPERATION_NOT_ALLOWED

    throw new OperationNotAllowedError(
      message,
      data.errors,
      response.status,
      data.message,
      data?.code
    )
  }

  if (!response.ok) {
    callbackError && callbackError(ERROR_MESSAGES.DEFAULT_ERROR)
    throw new Error(DEFAULT_ERROR)
  }

  return data
}
